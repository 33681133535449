<template>
  <div class="gpa_io">
    <div class="gpa_io-import" title="Import form">
      <input type="file" @change="importData" ref="dataFile" />
      <!-- @dragenter="mHover"
      @dragleave="mOut"-->
      <svg width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path
          fill="currentColor"
          d="M528 288h-92.1l46.1-46.1c30.1-30.1 8.8-81.9-33.9-81.9h-64V48c0-26.5-21.5-48-48-48h-96c-26.5 0-48 21.5-48 48v112h-64c-42.6 0-64.2 51.7-33.9 81.9l46.1 46.1H48c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V336c0-26.5-21.5-48-48-48zm-400-80h112V48h96v160h112L288 368 128 208zm400 256H48V336h140.1l65.9 65.9c18.8 18.8 49.1 18.7 67.9 0l65.9-65.9H528v128zm-88-64c0-13.3 10.7-24 24-24s24 10.7 24 24-10.7 24-24 24-24-10.7-24-24z"
          class
        />
      </svg>
    </div>
    <div class="gpa_io-export" @click="exportData" title="Export form">
      <svg width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path
          fill="currentColor"
          d="M528 288H384v-32h64c42.6 0 64.2-51.7 33.9-81.9l-160-160c-18.8-18.8-49.1-18.7-67.9 0l-160 160c-30.1 30.1-8.7 81.9 34 81.9h64v32H48c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V336c0-26.5-21.5-48-48-48zm-400-80L288 48l160 160H336v160h-96V208H128zm400 256H48V336h144v32c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48v-32h144v128zm-40-64c0 13.3-10.7 24-24 24s-24-10.7-24-24 10.7-24 24-24 24 10.7 24 24z"
          class
        />
      </svg>
    </div>
  </div>
</template>

<script>
import FileSaver from "file-saver";
export default {
  name: "ImportExport",
  props: {
    data: String,
    type: {
      type: String,
      default: ""
    },
    fileName: {
      type: String,
      default: "form-output.json"
    }
  },
  methods: {
    importData() {
      if (!this.$refs.dataFile) return;

      let files = this.$refs.dataFile.files;
      if (files.length > 0) {
        // this.$emit("startLoad");
        let fileDat = files[0];
        // console.log(fileDat);
        let fName = fileDat.name;
        let fread = new FileReader();
        fread.onload = evt => {
          this.$refs.dataFile.value = "";
          let data = evt.target.result;
          // console.warn(data)
          this.$emit("import", data);
        };
        fread.onerror = () => {
          this.$refs.dataFile.value = "";
        };
        fread.readAsText(fileDat);
      } else {
        // alert("LOI ROI HIHI");
      }
    },
    exportData() {
      if (this.data) {
        var blob = new Blob([this.data], {
          type: "data:application/json;charset=utf-8"
        });
        encodeURIComponent()
        let fName = this.fileName;
        if (!fName.endsWith(".json"))
          fName += ".json";
        FileSaver.saveAs(blob, fName);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.gpa_io {
  display: flex;
  border: 1px solid #ccc;
  &-import,
  &-export {
    cursor: pointer;
    display: flex;
    $size: 35px;
    width: $size;
    height: $size;
    justify-content: center;
    align-items: center;
    &:hover,
    &.is-hover {
      background-color: #ddd;
      color: #547aff;
      cursor: pointer;
    }
  }
  &-import {
    order: 0;
    position: relative;
    overflow: hidden;
  }
  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    display: block;
  }
  &-export {
    order: 1;
    border-left: 1px solid #ccc;
  }
}
</style>

