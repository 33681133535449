<template>
  <SelectValue title="Category" @input="onInput" v-model="val" :className="className" :readonly="readonly" :options="categoriesOptions">
    <template v-slot:actions v-if="error">
      <div class="reroll" @click="fetchCategory" :class="{'spinning': loading}">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M500.33 0h-47.41a12 12 0 0 0-12 12.57l4 82.76A247.42 247.42 0 0 0 256 8C119.34 8 7.9 119.53 8 256.19 8.1 393.07 119.1 504 256 504a247.1 247.1 0 0 0 166.18-63.91 12 12 0 0 0 .48-17.43l-34-34a12 12 0 0 0-16.38-.55A176 176 0 1 1 402.1 157.8l-101.53-4.87a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12h200.33a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12z"
            class
          />
        </svg>
      </div>
    </template>
  </SelectValue>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "SelectCategory",
  props: {
    value: Number,
    className: String,
    readonly: {
      type: Boolean, 
      default: false
    }
  },
  data() {
    return {
      val: ""
    };
  },
  computed: {
    ...mapState({
      categories: state => state.snippet.category.list,
      error: state => state.snippet.category.error,
      loading: state => state.snippet.category.loading
    }),
    categoriesOptions() {
      if (!this.categories) {
        return [];
      }
      return this.categories.map(x => ({
        value: x.id,
        text: x.name
      }))
    }
  },
  methods: {
    onInput(e) {
      // let value = e.target.value;
      // console.log(e.target.value);
      this.$emit("input", this.val);
      this.$emit("change", this.val);
    },
    ...mapActions({
      fetchCategory: "fetchSnippetCategories"
    })
  },
  created() {
    this.val = this.value;
    if (!this.categories || !this.categories.length) this.fetchCategory();
  },
  watch: {
    value(newV) {
      this.val = newV;
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes spinning {
  from {
    transform: rotate(14deg)
  }
  to {
    transform: rotate(373deg)
  }
}
.reroll {
  position: absolute;
  top: 25%;
  right: 15px;
  color: #777;
  svg {
    transform: rotate(14deg)
  }
  &:hover {
    color: #ff4757;
    cursor: pointer;
  }
  &.spinning {
    svg {
      animation: spinning 1000ms linear 0s alternate;
    }
  }
}

</style>
