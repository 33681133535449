<template>
  <div class="form-group" :class="className">
    <label class="gf_col-sm-4 gf_col-md-5">
      <p>{{title}}</p>
      <p class="notify notify-table" v-if="!isCode">(Press "Enter" after input to create new attribute)</p>
      <p class="notify notify-code" v-else>(Press "Ctrl + S" (or Command + S in Mac) to update data from code editor into table)</p>
      <template v-if="messageNote">
        <p class="note-code" v-for="(mes, key) in messageNote" :key="key">
          <span>{{ key }}</span>: {{ mes }}
        </p>
      </template>
    </label>

    <div class="gf_col-sm-8 gf_col-md-7 gf_no-padding d-flex flex-column">
      <div class="gf_col-sm-12 gf_col-md-12 gf_flex gf_no-padding bounder">
        <ObjectCreator :required="isRequired" :checkBoolean="checkBoolean" :readonly="readonly" v-model="val" @input="onOCInput" v-show="!isCode" ref="objectCreator"/>
        <CodeEditor :readonly="readonly" name="json_editor" :height="height+'px'" lang="json" v-model="jsonVal" v-show="isCode" @sync="syncData" ref="codeEditor"/>
        <button class="swap" type="button" @click="toggleMode" :title="isCode ? 'Switch to Object table' : 'Switch to JSON editor'">
          <svg width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" v-if="isCode">
            <path
              fill="currentColor"
              d="M208 32h-88a56 56 0 0 0-56 56v77.49a40 40 0 0 1-11.72 28.29L7 239a24 24 0 0 0 0 34l45.24 45.24A40 40 0 0 1 64 346.52V424a56 56 0 0 0 56 56h88a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16h-88a8 8 0 0 1-8-8v-77.48a88.06 88.06 0 0 0-25.78-62.24L57.93 256l28.29-28.28A88.06 88.06 0 0 0 112 165.48V88a8 8 0 0 1 8-8h88a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zm361 207l-45.25-45.24A40.07 40.07 0 0 1 512 165.48V88a56 56 0 0 0-56-56h-88a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h88a8 8 0 0 1 8 8v77.48a88 88 0 0 0 25.78 62.24L518.06 256l-28.28 28.28A88 88 0 0 0 464 346.52V424a8 8 0 0 1-8 8h-88a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h88a56 56 0 0 0 56-56v-77.49a40 40 0 0 1 11.72-28.29L569 273a24 24 0 0 0 0-34z"
              class
            />
          </svg>
          <svg width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" v-else>
            <path
              fill="currentColor"
              d="M0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48H48C21.49 32 0 53.49 0 80zm480 0v90.667H192V64h272c8.837 0 16 7.163 16 16zm0 229.333H192V202.667h288v106.666zM32 202.667h128v106.667H32V202.667zM160 64v106.667H32V80c0-8.837 7.163-16 16-16h112zM32 432v-90.667h128V448H48c-8.837 0-16-7.163-16-16zm160 16V341.333h288V432c0 8.837-7.163 16-16 16H192z"
              class
            />
          </svg>
        </button>
      </div>
      <p class="notify" :class="syncStatus.name" v-if="syncStatus">{{syncStatus.message}}</p>
      <p class="notify red--text" v-if="requiredText">{{requiredText}}</p>
      <p class="notify success--text" v-if="syncSuccess">{{syncSuccess}}</p>
    </div>
  </div>
</template>

<script>
import CodeEditor from "@/components/CodeEditor.vue";

import { isEqualObject as isEqual } from "@/utils/compare.js";

export default {
  name: "KeyValue",
  components: {
    CodeEditor
  },
  props: {
    title: String,
    placeholder: String,
    messageNote: Object,
    value: Object,
    jsonMode:{
      type:Boolean,
      default: false
    },
    className: {
      type: String,
      default: "gf_col-sm-12 gf_col-md-12"
    },
    readonly: {
      type: Boolean,
      default: false
    },
    checkBoolean: {
      type: Boolean,
      default: false
    },
    required: {
      type: Array,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      val: {},
      isCode: false,
      jsonVal: "",
      height: 0,
      syncSuccess: null,
      timeout: null
    };
  },
  computed: {
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
    requiredText() {
      if (!this.required || !this.isRequired)
        return undefined;
      let attrs = this.required.join(", ");
      return "This field require at least ["+attrs+"] to be defined.";
    },
    isRequired() {
      if (!this.required) {
        return false;
      }
      for (let i = 0; i < this.required.length; i++) {
        if (!this.val.hasOwnProperty(this.required[i])) {
          return true;
        }
        if (this.val[this.required[i]] == "")
          return true;
      }

      return false;
    },
    attrs() {
      const { rows, ...attrs } = this.$attrs;
      return attrs;
    },
    syncStatus() {
      let obj = this.verifyJSON(this.jsonVal);
      if (!obj) {
        return {
          name: "error",
          message: "Data is not JSON"
        };
      }
      if (!this.isCode)
        return null;
      if (!isEqual(obj, this.val)) {
        return {
          name: "warning",
          message: "JSON data hasn't been sync to table. Press Ctrl + S (or Command + S) to sync."
        };
      }
      return null;
    }
  },
  methods: {
    toggleMode() {
      this.isCode = !this.isCode;
    },
    onInput(e) {
      // let value = e.target.value;
      // console.log(e.target.value);
      this.$emit("input", this.val);
    },
    onOCInput(e) {
      this.onInput(e);
      this.calcHeight();
      this.jsonVal = JSON.stringify(this.value, null, 2);
    },
    calcHeight() {
      let keys = Object.keys(this.val);
      let rows = keys.length + 1;
      let height = 34 + rows*35;
      // console.log({
      //   keys,
      //   rows,
      //   height
      // })
      // let oc = this.$refs.objectCreator;
      // if (!oc)
      //   return;
      // let height = 0;
      // if (oc) {
      //   height = oc.$el.getBoundingClientRect().height;

      // }
      // if (height < 100)
      //   height = 100;
      // console.log({
      //   height
      // })
      this.height = height;
    },
    syncData() {
      // console.log("====================SYNCING=================")
      let val = this.verifyJSON(this.jsonVal);
      if (val) {
        this.val = val;
        this.onOCInput();
        this.isCode = false;
        this.syncSuccess = "Sync successfully!";
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.syncSuccess = null;
        }, 2000);
      }
    },
    verifyJSON(json) {
      if (json && json.length) {
        try {
          let val = JSON.parse(json);
          return val;
        } catch (error) {
          return undefined;
        }
      }
    },
    // check() {
    //   console.log({
    //     val: this.val,
    //     json: this.verifyJSON(this.jsonVal),
    //     isEqual: !isEqual(this.verifyJSON(this.jsonVal), this.val)
    //   })
    // }
  },
  created() {
    this.val = this.value;
    this.jsonVal = JSON.stringify(this.value, null, 2);
    this.calcHeight();
    if (this.jsonMode){
      this.isCode = true;
    }
  },
  watch: {
    value(newV) {
      this.val = newV;
      this.jsonVal = JSON.stringify(newV, null, 2);
      this.calcHeight();
    },
    isCode(newVal) {
      this.calcHeight();
      if (newVal) {
        this.$nextTick(() => {
          this.$refs.codeEditor.$children[0].$refs.editor.env.editor.renderer.$renderChanges();
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.swap {
  border-radius: 0;
  // box-shadow: 0 0 2px 2px #575fcf;
  z-index: 1;
  position: absolute;
  padding: 0.4em 0.5em;
  top: -1px;
  left: calc(0px - 0.5em * 2 - 20px)
}
.notify {
  font-weight: bold;
  font-size: 13px;
  margin-top: 5px;
  &-code {
  }
  &-table {
  }
  &.warning {
    color: #f0932b;
  }
  &.error {
    color: #eb4d4b;
  }
  &.success {
    color: #6ab04c;
  }
}
.bounder {
  margin-bottom: 1rem;
}
.note-code {
  margin-bottom: 0px;
  font-size: 12px;
  span {
    font-style: italic;
    color: #000;
  }
  :first {
    margin-top: 5px;
  }
}
</style>
