/**
 * Compare two object if they are equals
 * @param {Object} object
 * @param {Object} other
 */
export function isEqualObject(object = {}, other = {}) {
  let keys1 = Object.keys(object);
  let keys2 = Object.keys(other);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let i = 0, len = keys1.length; i < len; i++) {
    let key = keys1[i];
    if (object[key] != other[key]) {
      return false;
    }
  }
  return true;
}

/**
 * Compare two array if they are equal.
 * @param {Array<*>} array 
 * @param {Array<*>} other 
 * @param {(a, b)} comparer 
 */
export function isEqualArray(array = [], other = [], comparer = undefined) {
  if (array.length !== other.length) {
    return false;
  }
  if (array.length === 0) {
    return false;
  }

  let _array = Array.from(array);
  let _other = Array.from(other);

  if (comparer) {
    _array.sort(comparer);
    _other.sort(comparer);
    for (let i = 0, len = _array.length; i < len; i++) {
      if (comparer(_array[i],_other[i]) !== 0) {
        return false;
      }
    }
  } else {
    _array.sort();
    _other.sort();
    for (let i = 0, len = _array.length; i < len; i++) {
      if (_array[i] !== _other[i]) {
        return false;
      }
    }
  }
  return true;

}

